import Swiper, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";

const cardSlider = document.querySelectorAll(".card-slider");
if(cardSlider.length > 0) {
  cardSlider.forEach(slider => {
    const slides = slider.querySelectorAll('.swiper-slide');
    new Swiper(slider, {
      modules: [Navigation],
      spaceBetween: 20,
      slidesPerView: slides.length === 1 ? 1 : 1.1,
      navigation: {
        nextEl: slider.querySelector(".swiper-button-next"),
        prevEl: slider.querySelector(".swiper-button-prev"),
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
      },
    });
  });
}

const pricingSlider = document.querySelectorAll(".pricing-slider");
if(pricingSlider.length > 0) {
  pricingSlider.forEach(slider => {
    const numSlides = parseInt(slider.getAttribute('data-num-slides')) || 4;
    console.log(numSlides);
    new Swiper(slider, {
      modules: [Navigation],
      spaceBetween: 20,
      slidesPerView: 1.3,
      navigation: {
        nextEl: slider.querySelector(".swiper-button-next"),
        prevEl: slider.querySelector(".swiper-button-prev"),
      },
      breakpoints: {
        768: {
            slidesPerView: 2.3,
        },
        1024: {
          slidesPerView: 3.3,
        },
        1300: {
          slidesPerView: numSlides,
        },
      }
    });
  });
}

const membershipSlider = document.querySelectorAll(".pricing-slider");
if(membershipSlider.length > 0) {
  membershipSlider.forEach(slider => {
    const numSlides = parseInt(slider.getAttribute('data-num-slides')) || 4;
    const swiperInstance = new Swiper(slider, {
      modules: [Navigation],
      spaceBetween: 20,
      slidesPerView: 1.3,
      navigation: {
        nextEl: slider.querySelector(".btn-secondary:last-child"),
        prevEl: slider.querySelector(".btn-secondary:first-child"),
      },
      breakpoints: {
        768: {
            slidesPerView: 2.3,
        },
        1024: {
          slidesPerView: 3.3,
        },
        1300: {
          slidesPerView: numSlides,
        },
      }
    });
  });
}
const logoSlider = document.querySelectorAll(".logo-slider");
if(logoSlider.length > 0) {
  logoSlider.forEach(slider => {
    let init = false;
    let swiper;
    function logoSwiper() {
      if (window.innerWidth < 768 && !init) {
        slider.querySelector('.logo-slider-container').classList.add('swiper-wrapper');
        slider.querySelector('.logo-slider-container').classList.remove('-mx-6', 'grid', 'grid-cols-2', 'gap-5', 'sm:mx-0', 'md:grid-cols-4');
        swiper = new Swiper(slider, {
          slidesPerView: 2.1,
          spaceBetween: 10,
        });
        init = true;
      } else if (window.innerWidth >= 768 && init) {
        slider.querySelector('.logo-slider-container').classList.add('-mx-6', 'grid', 'grid-cols-2', 'gap-5', 'sm:mx-0', 'md:grid-cols-4');
        slider.querySelector('.logo-slider-container').classList.remove('swiper-wrapper');
        swiper.destroy();
        init = false;
      }
    }
    logoSwiper();
    window.addEventListener("resize", logoSwiper);
    window.addEventListener("load", logoSwiper);
  });
}